import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../layout/layout.css"
import * as TeamItemStyles from "../teamItem"
import * as tgStyles from "./teamGrid.module.css"
import TeamItem from "../teamItem"


const TeamGrid = (props) => {
  const [animation, setAnimation] = useState()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    gsap.set(".kachel", { opacity: 0 });
    ScrollTrigger.batch(".kachel", {
      // onEnter: batch => gsap.to(batch, {opacity: 0.5, stagger: 0.1})
      start: "center center",
      onEnter: (elements, triggers) => {
        gsap.to(elements, { opacity: 1, stagger: 0.15, overwrite: true });
      },
      markers: false
    })

  }, [])

  const data = useStaticQuery(graphql`
  query TeamGridQuery {  
    allTeamJson {
      edges {
        node {
          name
          desc
          image {
            childImageSharp {
              gatsbyImageData(          
                placeholder: BLURRED,
                aspectRatio: 1
              )
            }
          }     
          about {
            childImageSharp {
              gatsbyImageData(          
                placeholder: BLURRED,
                aspectRatio: 1
              )
            }
          }                
        }
      }
    }
  }
`)

  return (
    <div className="container-fluid">
      <div className={`row ${tgStyles.sectionsetup}`}>
        {/* fuer jedes team member eine kachel  */}
        {data.allTeamJson.edges.map(({ node }) => (
          <TeamItem key={node.id} myTeam={node} ani={animation} navi={props.navi} pagination={props.pagination}/>
        ))}
      </div>
    </div>
  )
}

export default TeamGrid