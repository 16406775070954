import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import "../components/layout/layout.css"
import {AnchorLink} from "gatsby-plugin-anchor-links"
import TeamGrid from "../components/teamGrid"
import WhatsNext from "../components/whatsNext/whatsnext"
import * as teamStyles from "./team.module.css"
import BigHeaderVideo from "../components/header/bigHeader/bigHeaderVideo"
import myLocalVid from '../images/team/team-header.mp4'
import InstagramFeed from "../components/instagramFeed"

const Team = () => {

  const data = useStaticQuery(graphql`
    query {
        ogimage: file(relativePath: { eq: "images/team-og.jpg" }) {
            childImageSharp {
                fixed(height: 400) {
                    src
                    height
                    width
                }
            }
        }
    }
  `)  

  return (
    <Layout>
      <SEO
        title="Team"
        description="Bei uns treffen sich Strategen und Konzepter, Designer und Wortakrobaten, Programmierer und Projektmanager – so vereinen wir alle Fähigkeiten, die für Ihre Lösungen gebraucht werden."
        image={data?.ogimage?.childImageSharp?.fixed}
      />            
      
      {/* 
        header
      */}
      <BigHeaderVideo mode="allatonce" className={`${teamStyles.child}`} bgVideo={myLocalVid} videoMode="local">
        <div className="row">
          <div className="col-12 col-md-5">
            <h1>Team</h1>
            <p className="introtext">Bei uns treffen sich Strategen und Konzepter, Designer und Wortakrobaten, Programmierer und Projektmanager – so vereinen wir alle Fähigkeiten, die für Ihre Lösungen gebraucht werden.</p>
            <AnchorLink to="/team/#teamgrid" className="q-btn q-btn-primary">↓ Mehr erfahren</AnchorLink>
          </div>
          <div className="col-7"></div>
        </div>
      </BigHeaderVideo>      

      <div id="teamgrid">
        <TeamGrid navi={false} pagination={false}/>
      </div>

      {/* <InstagramFeed title="Insta-Feed" /> */}

      {/* 
        next
      */}      
      <WhatsNext text="Jobs &rarr;" link="/jobs"/>

    </Layout>
  )
}

export default Team
