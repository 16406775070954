import React, { useState, useCallback } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import * as TeamItemStyles from "./teamGrid-item.module.css"
import "../layout/layout.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SwiperSlide } from "swiper/react"
import SwiperDefault from "../swiperDefault"



const TeamItem = (props) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation()
      if (controlledSwiper?.slideNext) {
        controlledSwiper.slideNext()
      }
    },
    [controlledSwiper],
  )

  return (
    <div className={`col-6 col-md-6 col-lg-3 p-lg-3 col-xl-4 p-xl-4 ${TeamItemStyles.kachel}`}>
      <Link to={props.myTeam.link}>
        <div className={TeamItemStyles.layerWrap}>
          {/* htgr bild */}
          <div>
            <SwiperDefault fault slidesPerView={1} spaceBetween={0} effect={"flip"} navi={props.navi} pagination={props.pagination} setControlledSwiper={setControlledSwiper} controlledSwiper={controlledSwiper}>
              <SwiperSlide>
                <div className={`${TeamItemStyles.gridItem}`} onClick={handleClick}>
                  <GatsbyImage
                    image={props.myTeam.image.childImageSharp.gatsbyImageData}
                    alt={props.myTeam.desc}
                    placeholder="blurred"
                  />
                </div>
              </SwiperSlide>  
              <SwiperSlide>
                <div onClick={handleClick}>
                  <GatsbyImage
                    image={props.myTeam.about.childImageSharp.gatsbyImageData}
                    alt={props.myTeam.desc}
                    placeholder="blurred"
                  />
                </div>
              </SwiperSlide>
            </SwiperDefault>
          </div>
          {/* leiste */}
          <div className={TeamItemStyles.layerLeiste}>
            <div className={TeamItemStyles.label}>
              <h5 className="text-uppercase mb-0 pb-0">
                {/* {props.myTeam.name} */}
              </h5>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

// Specifies the default values for props:
TeamItem.defaultProps = {
  myTeam: {
    "allProjekteJson": {
      "totalCount": 11,
      "edges": [
        {
          "node": {
            "id": "da045667-782b-54c6-b3d8-05ee8e262ead",
            "type": "website",
            "scope": "hero",
            "link": "/",
            "logo": "./logo-bje.svg",
            "title": "Starclub",
            "linktitle": "mein linktitel",
            "slug": "starclub"
          }
        }
      ]
    }
  }
};

export default TeamItem





